import React from "react";

type Props = {
  labels: Object;
  data: any[];
  loading?: boolean;
  error?: any;
  emptyMessage?: string;
};

const Table = (props: Props) => {
  return (
    <div
      style={{ maxHeight: "100%", maxWidth: "100%" }}
      className="overflow-auto relative border-b rounded-md"
    >
      <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 ">
        <thead className="border-b text-gray-700 bg-gray-100 rounded-md sticky top-0">
          <tr>
            {Object.values(props.labels).map((label, index) => (
              <th key={index} scope="col" className="px-6 py-3 font-medium">
                {label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="">
          {props.loading ? (
            <tr>
              <td
                colSpan={Object.keys(props.labels).length}
                className="p-4 text-center"
              >
                Loading...
              </td>
            </tr>
          ) : props.error ? (
            <tr>
              <td
                colSpan={Object.keys(props.labels).length}
                className="p-4 text-failure-red"
              >
                {props.error || "Something went wrong."}
              </td>
            </tr>
          ) : props.data.length === 0 ? (
            <tr>
              <td colSpan={Object.keys(props.labels).length} className="p-4">
                {props.emptyMessage || "No data found."}
              </td>
            </tr>
          ) : (
            props.data.map((row, idx) => (
              <tr
                className="bg-white border-b border-gray-200 w-full"
                key={row?.id === "sample" ? idx : row?.id}
              >
                {Object.keys(props.labels).map((key, index) => (
                  <td
                    key={index}
                    className={`px-6 py-4 ${
                      index === 0 ? "text-gray-700 font-medium" : ""
                    }`}
                  >
                    {row[key]}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
