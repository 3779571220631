import React from "react";

type OnboardingState = "email" | "onboarded";

export type OnboardingContext = {
  onboardingState: OnboardingState;
  setOnboardingState: (state: OnboardingState) => void;
};

const onboardingContext = React.createContext<OnboardingContext>({
  onboardingState: "email",
  setOnboardingState: () => {},
});

export const OnboardingProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [onboardingState, setOnboardingState] = React.useState<OnboardingState>(
    // @ts-ignore
    localStorage.getItem("onboardingContext") || "email"
  );

  const handleSetOnboardingState = (state: OnboardingState) => {
    setOnboardingState(state);
    // @ts-ignore
    localStorage.setItem("onboardingContext", state);
  };
  return (
    <onboardingContext.Provider
      value={{ onboardingState, setOnboardingState: handleSetOnboardingState }}
    >
      {children}
    </onboardingContext.Provider>
  );
};

export default onboardingContext;
