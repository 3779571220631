import ContentLoader from "react-content-loader";
import Card from "./Card";
import GrowthComponent from "./GrowthComponent";
import { FiInfo } from "react-icons/fi";
import Tooltip, { TooltipChild } from "./Tooltip";

type TypeProps = {
  title: string;
  number?: number | null;
  growth: number;
  loading?: boolean;
  format?: string;
  info?: string;
};

const StatCard = (props: TypeProps) => {
  return (
    <Card
      className="flex-1"
      style={{
        maxWidth: 400,
      }}
    >
      <div className=" mb-2 flex relative">
        <div className="font-semibold">{props.title}</div>
        {props.info && (
          <div
            className="absolute right-0"
            style={{
              top: -4,
            }}
          >
            <Tooltip
              itemChild={
                <div className="text-gray-500 h-8 p-2 cursor-pointer bg-white hover:bg-gray-100 rounded-full">
                  <FiInfo />
                </div>
              }
              popoverChild={<TooltipChild text={props.info} />}
            />
          </div>
        )}
      </div>

      <div className="flex items-baseline">
        {props.loading ? (
          <div className="mt-1">
            <ContentLoader
              speed={2}
              width={40}
              height={32}
              viewBox="0 0 40 32"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="3" ry="3" width="40" height="32" />
            </ContentLoader>
          </div>
        ) : (
          <div className="text-2xl">
            {props.format === "2dp"
              ? props.number?.toFixed(2) || "0"
              : props.format === "%"
              ? Math.round(props.number || 0)
              : props.number
                  ?.toString()
                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",") || "0"}
            {props.format === "%" && "%"}
          </div>
        )}

        {props.loading ? (
          <div className="ml-2">
            <ContentLoader
              speed={2}
              width={40}
              height={24}
              viewBox="0 0 40 24"
              backgroundColor="#f3f3f3"
              foregroundColor="#ecebeb"
            >
              <rect x="0" y="0" rx="3" ry="3" width="40" height="24" />
            </ContentLoader>
          </div>
        ) : (
          <GrowthComponent number={props.growth} />
        )}
      </div>
    </Card>
  );
};

export default StatCard;
