import React from "react";

type TextInputProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> & {
  className?: string;
};

const defaultStyle =
  "focus:ring-0 w-96 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-main-yellow focus:border-white block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500";

const TextArea: React.FC<TextInputProps> = ({ className, ...rest }) => (
  <textarea className={`${defaultStyle} ${className}`} {...rest} />
);

export default TextArea;
