import React from "react";
import Page from "./Page";
import Header from "../Components/Header";
import { ReactComponent as Jigsaw } from "../images/piece.svg";

const ChromeExtension = () => {
  React.useEffect(() => {
    const env = process.env.NODE_ENV === "development" ? "dev" : "prod";
    window.postMessage({ type: "toggle-open", env }, "*");
  }, []);

  return (
    <Page childClass="w-xl">
      <Header title="Chrome extension" />

      <div className="my-4">
        <h2 className="text-2xl font-bold">How to log in</h2>
        <p className="text-gray-600">
          <ul className="list-disc list-inside">
            <li>Ensure you're using Google Chrome</li>
            <li>
              Click the jigsaw piece button on the top right of your browser{" "}
              <Jigsaw
                height={20}
                width={20}
                style={{
                  display: "inline",
                  position: "relative",
                  bottom: 2,
                  left: 2,
                }}
              />
            </li>
            <li>
              Find <i>Telepathic Demo Recordings</i> and click on the pin icon
            </li>

            <li>
              Click on the Telepathic logo that's just appeared next to the
              jigsaw piece while on this page
            </li>
          </ul>
        </p>
      </div>

      <div className="my-4">
        <h2 className="text-2xl font-bold">How to Record</h2>
        <p className="text-gray-600">
          To record your session, follow these steps:
          <ul className="list-disc list-inside">
            <li>Speak clearly and concisely.</li>
            <li>Explain each screen, then click</li>
            <li className="">Do not include any confidential information!</li>
          </ul>
        </p>
      </div>
    </Page>
  );
};

export default ChromeExtension;
