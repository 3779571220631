export const addViewsFromFile = async (
  clientId: string,
  file: File,
  token: string
) => {
  try {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("client_id", clientId);

    const response = await fetch(
      `${process.env.REACT_APP_API_ROOT}/recording/${clientId}/view_from_file/`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      }
    );
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
  }
};
