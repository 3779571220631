import React, { useContext } from "react";
import Page from "./Page";
import Header from "../Components/Header";
import Button from "../Components/Button";
import { useAddClientContextMutation, useGetMeFullQuery } from "../gql/schema";
import { toast } from "react-toastify";
import { VscLoading } from "react-icons/vsc";
import { FiFile, FiInfo, FiX } from "react-icons/fi";
import Tooltip, { TooltipChild } from "../Components/Tooltip";
import TextArea from "../Components/TextArea";
import Card from "../Components/Card";
import { addViewsFromFile } from "../api/addViewFromFile";
import authContext from "../contexts/authContext";

const Dojo = () => {
  const { authToken } = useContext(authContext);

  const { data, loading: dataLoading } = useGetMeFullQuery();

  const initialUnderstanding =
    data?.getMe?.seller?.client?.additionalContext?.trim() || "";
  const clientId = data?.getMe?.seller?.client?.id;
  const [understandingPrompt, setUnderstandingPrompt] =
    React.useState(initialUnderstanding);
  React.useEffect(() => {
    if (!dataLoading) {
      setUnderstandingPrompt(
        data?.getMe?.seller?.client?.additionalContext || ""
      );
    }
  }, [data, dataLoading]);

  const [errorText, setErrorText] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [addClientContext] = useAddClientContextMutation();
  const [files, setFiles] = React.useState<File[]>([]);

  const disabled = React.useMemo(
    () =>
      loading ||
      (understandingPrompt.trim() === initialUnderstanding &&
        files.length === 0),
    [understandingPrompt, initialUnderstanding, loading, files]
  );

  const handleSubmit = React.useCallback(async () => {
    if (disabled) return;
    setErrorText("");
    setLoading(true);
    let success = true;
    try {
      if (understandingPrompt.trim() !== initialUnderstanding) {
        const response = await addClientContext({
          variables: {
            text: understandingPrompt,
          },
        });
        if (response?.data?.addClientContext?.client?.id) {
        } else {
          success = false;
          setErrorText(
            response?.data?.addClientContext?.error?.message ||
              "Something went wrong"
          );
        }
      }
      for (let f of files) {
        if (!!f && !!authToken) {
          const response = await addViewsFromFile(clientId, f, authToken);

          if ((response?.views || []).length > 0) {
            setFiles([]);
          } else {
            success = false;
            setErrorText(
              response?.data?.addViewsFromFile?.error?.message ||
                "Something went wrong"
            );
          }
        }
      }

      if (success) {
        toast.success(`AI trained successfully!`);
      }
    } catch (e) {
      setErrorText(e?.toString() || "");
    }
    setLoading(false);
  }, [
    addClientContext,
    understandingPrompt,
    disabled,
    initialUnderstanding,
    files,
    clientId,
    authToken,
  ]);

  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        e.key === "Enter" &&
        !disabled &&
        e.shiftKey === false &&
        !!e.metaKey
      ) {
        handleSubmit();
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [disabled, handleSubmit]);

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFiles((prevFiles) => [
      ...prevFiles,
      ...Array.from(e.target.files || []),
    ]);
  };

  return (
    <Page>
      <Header title="AI training dojo" />
      <div className="mb-4 flex">
        <div className="mr-8">
          <div className="mb-2">
            <p className="text-gray-500 font-regular max-w-2xl">
              Tell your AI things to make it smarter.{" "}
            </p>
          </div>
          <div className="">
            <Card className="flex-1 grow-0">
              <div className="">
                <label
                  htmlFor="description"
                  className="mb-2 text-sm font-medium text-gray-900 dark:text-white flex items-center"
                >
                  Set instructions
                  <Tooltip
                    itemChild={<FiInfo className="ml-1 text-gray-500" />}
                    popoverChild={
                      <TooltipChild
                        text={
                          "Tell the AI what questions to ask, what its objective of the call should be, and any other information it should know."
                        }
                      />
                    }
                  ></Tooltip>
                </label>
                <TextArea
                  id="description"
                  required
                  value={understandingPrompt}
                  onChange={(e) => setUnderstandingPrompt(e.target.value)}
                  rows={4}
                  placeholder="Eg. Find out their name, company, and use case before booking a call with them."
                  className="border-none !bg-gray-100"
                  style={{ width: 400 }}
                />
              </div>
            </Card>
            <Card className="flex-1 grow-0" style={{ maxWidth: 500 }}>
              <div className="">
                <label
                  htmlFor="description"
                  className="mb-2 text-sm font-medium text-gray-900 dark:text-white flex items-center"
                >
                  Demo content upload
                  <Tooltip
                    itemChild={<FiInfo className="ml-1 text-gray-500" />}
                    popoverChild={
                      <TooltipChild
                        text={
                          "This is the content shown in the middle of the screen during the demo. For now we only accept PDFs."
                        }
                      />
                    }
                  ></Tooltip>
                </label>
                <Button
                  variant="outlined"
                  className="mb-2"
                  onClick={() => document.getElementById("fileInput")?.click()}
                >
                  Upload file
                </Button>
                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleFileUpload}
                />
                {files.length > 0 && (
                  <div className="flex items-center flex-wrap">
                    {files.map((f) => (
                      <div
                        key={f.name}
                        className="text-gray-500 mb-2 text-sm hover:bg-gray-50 cursor-pointer mr-2 border border-gray-300 rounded-lg p-1 px-2 flex items-center justify-center"
                        onClick={() => setFiles(files.filter((ff) => ff !== f))}
                      >
                        <FiFile className="mr-1 h-4 w-4 text-main-yellow" />
                        {f.name}
                        <FiX className="ml-1 h-3 w-3" />
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </Card>
          </div>

          {errorText && (
            <div className="text-failure-red text-sm mb-4">{errorText}</div>
          )}

          <div className="flex items-center">
            <Button onClick={handleSubmit} disabled={disabled} className="mr-2">
              Save
            </Button>
            <Button
              variant="outlined"
              onClick={() =>
                (window.location.href = `${process.env.REACT_APP_TESTING_ROOT}/accounts/${clientId}`)
              }
            >
              Visit playground
            </Button>
            {loading && (
              <VscLoading className="animate-spin-fast text-gray-500" />
            )}
            {loading && (
              <div className="text-gray-500 ml-2">
                This might take a few moments...
              </div>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default Dojo;
