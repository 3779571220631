import React from "react";

type Props = {
  title: string;
};

const Header = (props: Props) => {
  return (
    <div
      className="h-32 flex flex-col justify-end mb-6"
      style={{ minHeight: "128px" }}
    >
      <h3 className="text-2xl font-bold">{props.title}</h3>
    </div>
  );
};

export default Header;
