import React from "react";
import ReactDOM from "react-dom";

type PopoverProps = {
  itemChild: React.ReactNode;
  popoverChild: React.ReactNode;
};

type PopoverChildProps = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

export const PopoverChild = (props: PopoverChildProps) => {
  return (
    <div
      className={`rounded-lg drop-shadow shadow relative z-10 bg-white border  ${
        props.className || ""
      }`}
      style={props.style || {}}
    >
      {props.children}
    </div>
  );
};

const Popover = (props: PopoverProps) => {
  const [open, setOpen] = React.useState(false);
  const childRef = React.useRef<HTMLDivElement>(null);
  const triggerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!triggerRef.current || !childRef.current) return;

    // Ensure the tooltip is visible before calculating dimensions
    childRef.current.style.visibility = "hidden";
    childRef.current.style.display = "block";

    const triggerRect = triggerRef.current.getBoundingClientRect();
    const tooltipRect = childRef.current.getBoundingClientRect();

    const viewportWidth = window.innerWidth;
    const margin = 12; // Margin from the edge of the window

    let left =
      triggerRect.left +
      window.scrollX +
      triggerRect.width / 2 -
      tooltipRect.width / 2;

    // Adjust left position if the tooltip is going off the right edge of the screen
    if (left + tooltipRect.width > viewportWidth - margin) {
      left = viewportWidth - tooltipRect.width - margin;
    }

    // Adjust left position if the tooltip is going off the left edge of the screen
    if (left < margin) {
      left = margin;
    }

    const top = triggerRect.top + window.scrollY - tooltipRect.height - margin;

    childRef.current.style.top = `${top}px`;
    childRef.current.style.left = `${left}px`;

    // Make the tooltip visible after positioning
    childRef.current.style.visibility = "visible";
    childRef.current.style.display = open ? "block" : "none";
  }, [open]);

  React.useEffect(() => {
    if (open) {
      const handleClick = (e: MouseEvent) => {
        if (
          !childRef.current?.contains(e.target as Node) &&
          !triggerRef.current?.contains(e.target as Node)
        ) {
          setOpen(false);
        }
      };

      document.addEventListener("click", handleClick);

      return () => {
        document.removeEventListener("click", handleClick);
      };
    }
  }, [open]);

  return (
    <div className="relative">
      <div
        className="focus:outline-none cursor-pointer"
        onClick={() => setOpen(true)}
        ref={triggerRef}
      >
        {props.itemChild}
      </div>
      {ReactDOM.createPortal(
        <div
          className={`z-10 max-w-md ${open ? "block" : "hidden"}`}
          style={{ position: "fixed" }} // Use fixed positioning
          ref={childRef}
        >
          {props.popoverChild}
        </div>,
        document.body
      )}
    </div>
  );
};

export default Popover;
