import React, { useContext } from "react";
import TextInput from "./TextInput";
import { FiExternalLink, FiHelpCircle, FiSearch, FiUser } from "react-icons/fi";
import { getDirectUrl } from "../utils";
import userContext from "../contexts/userContext";
import Tooltip, { TooltipChild } from "./Tooltip";

type Props = {
  text: string | null;
  url: string | null;
  imageUrl: string | null;
};

const SearchBar = (props: Props) => {
  const hasResult = !!props.url;

  const { user } = useContext(userContext);

  const goDirectUrl = React.useMemo(() => {
    if (!!props.url) {
      const trainingOrigin = user?.seller?.client?.productTrainingOrigin;
      const theUrl = getDirectUrl(
        window.location.href,
        props.url,
        trainingOrigin,
        !!props.imageUrl
      );
      if (window.location.href === theUrl) {
        return null;
      }
      return theUrl;
    }
    return null;
  }, [props.url, props.imageUrl]);

  return (
    <div
      className={`mb-2 flex items-center  ${
        hasResult ? "justify-between" : "justify-center"
      }`}
      style={{
        width: 800,
      }}
    >
      <div className="flex-1 flex justify-start items-center">
        {hasResult && (
          <div className="rounded-lg text-black bg-white hover:text-black cursor-not-allowed mr-2 p-2 flex text-base items-center  border-2 border-gray-300 active:shadow active:shadow-gray-400 whitespace-nowrap">
            <FiHelpCircle className="" size={20} />
          </div>
        )}
      </div>
      <div className="relative">
        <TextInput
          className="placeholder:text-gray-500 focus:!outline-none focus:!border-gray-300 shadow-md bg-white text-base border-2  cursor-not-allowed !px-3 !pr-12"
          style={{ width: "500px", paddingTop: "10px", paddingBottom: "10px" }}
          placeholder="Show me... "
          value={props.text || ""}
          autoFocus
        />
        <div
          className={`absolute bg-white text-gray-500 rounded-full p-2 `}
          style={{ right: "8px", bottom: "4px" }}
        >
          <FiSearch stroke={"#000"} size={20} />
        </div>
      </div>
      <div className="flex-1 flex justify-end items-start h-12">
        {hasResult && (
          <Tooltip
            itemChild={
              <a
                className="rounded-lg py-2 px-4 flex text-base items-center cursor-pointer font-semibold text-black border-2 border-gray-500 hover:border-gray-800 active:shadow active:shadow-gray-400"
                target="_blank"
                href={goDirectUrl || undefined}
                style={{}}
              >
                Go there
                <FiExternalLink className="ml-1 text-main-yellow" />
              </a>
            }
            popoverChild={<TooltipChild text="Go direct to page" />}
          />
        )}
      </div>
    </div>
  );
};

export default SearchBar;
