import React from "react";

type Props = {
  mouseCoordinates: any;
};

export const captureDimensions = {
  width: 1512,
  height: 982,
};

export const displayDimensions = {
  width: 800,
  height: 533,
};

const buffer = 10;
const arrowHeight = 100;
const arrowWidth = 150;
export const boxWidth = 240;

export const scaleX = (x: number) => {
  if (!x) return 0; // Return 0 if x is 0 or undefined
  const scaledX = (x * displayDimensions.width) / captureDimensions.width;
  return Math.min(scaledX, displayDimensions.width - buffer); // Ensure value is within bounds
};

export const scaleY = (y: number) => {
  if (!y) return 0; // Return 0 if y is 0 or undefined
  const scaledY = (y * displayDimensions.height) / captureDimensions.height;
  return Math.min(scaledY, displayDimensions.height - buffer); // Ensure value is within bounds
};

export type TextLocationType = null | {
  arrow: React.CSSProperties;
  text: React.CSSProperties;
};

const useTextLocation = ({ mouseCoordinates }: Props): TextLocationType => {
  return React.useMemo(() => {
    if (!mouseCoordinates) {
      return null;
    }
    const startingLocation = mouseCoordinates;

    const calculateDirection = (x: number) =>
      x < displayDimensions.width / 2 ? "left" : "right";

    const location = {
      x: scaleX(startingLocation.x),
      y: scaleY(startingLocation.y),
      direction: calculateDirection(startingLocation.x),
    };
    let arrowStyles: any = {};
    let textStyles: any = {};

    const scaledHeight = scaleY(startingLocation.height || 0);
    const scaledWidth = scaleX(startingLocation.width || 0);

    const arrowPointerHeightShift = 16;
    if (location.y < displayDimensions.height / 2) {
      // upper section
      textStyles["top"] =
        location.y +
        arrowHeight -
        arrowPointerHeightShift +
        (scaledHeight * 3) / 4;
      arrowStyles["top"] =
        location.y - arrowPointerHeightShift + (scaledHeight * 3) / 4;
    } else {
      // lower section
      arrowStyles["transform"] = "scaleY(-1)";
      arrowStyles["bottom"] =
        displayDimensions.height -
        location.y -
        arrowPointerHeightShift -
        (scaledHeight * 1) / 4;
      textStyles["bottom"] =
        displayDimensions.height -
        location.y +
        arrowHeight -
        arrowPointerHeightShift -
        (scaledHeight * 1) / 4;
    }

    const pointLeft =
      location.direction === "left" ||
      (location.direction !== "right" &&
        location.x < displayDimensions.width / 2);
    if (pointLeft) {
      arrowStyles["left"] = location.x - 8 + (scaledWidth * 7) / 8;
      textStyles["left"] =
        location.x - boxWidth / 2 + arrowWidth - 8 + (scaledWidth * 7) / 8;
      if (arrowStyles["transform"] === "scaleY(-1)") {
        arrowStyles["transform"] = "scale(-1, -1)";
      } else {
        arrowStyles["transform"] = "scaleX(-1)";
      }
    } else {
      arrowStyles["right"] = displayDimensions.width - location.x - 10;
      textStyles["right"] =
        displayDimensions.width - location.x + arrowWidth - boxWidth / 2 - 10;
    }
    return {
      arrow: arrowStyles,
      text: textStyles,
    };
  }, [mouseCoordinates]);
};

export default useTextLocation;
