import { FiArrowDown, FiArrowUp } from "react-icons/fi";

export type GrowthProps = {
  number: number;
};

export const getDelta = (current?: number | null, prev?: number | null) => {
  if (prev === 0) {
    if (!!current) {
      return 100;
    } else {
      return 0;
    }
  } else {
    if (!!current && !!prev) {
      const delta = ((current - prev) / prev) * 100;
      return delta;
    } else {
      return 0;
    }
  }
};

const GrowthComponent = (props: GrowthProps) => {
  const color =
    props.number === 0
      ? "text-gray-500"
      : props.number > 0
      ? "text-success-green"
      : "text-failure-red";
  return (
    <div className={`ml-2 flex ${color}`}>
      {props.number === 0 ? (
        <FiArrowUp className="mr-0.5" size={12} style={{ width: 0 }} />
      ) : props.number > 0 ? (
        <FiArrowUp className="mr-0.5" size={12} />
      ) : (
        <FiArrowDown className="mr-0.5" size={12} />
      )}
      <div className="relative bottom-1.5">
        {Math.abs(props.number).toFixed(0)}%
      </div>
    </div>
  );
};

export default GrowthComponent;
