import React from "react";

type Props = {
  children: React.ReactNode;
  onClick: (e: any) => void;
  className?: string;
  styles?: React.CSSProperties;
  disabled?: boolean;
};

const IconButton = (props: Props) => {
  return (
    <div
      className={`dark:bg-gray-800 rounded-full p-2 ${
        props.disabled
          ? "cursor-not-allowed text-gray-400"
          : "cursor-pointer text-gray-500 hover:bg-gray-100"
      } ${props.className || ""}`}
      onClick={props.disabled ? () => {} : props.onClick}
      style={props.styles || {}}
    >
      {props.children}
    </div>
  );
};

export default IconButton;
