import React from "react";
import Page from "./Page";
import Header from "../Components/Header";
import {
  useChangeSellerClientMutation,
  useGetAdminStatsQuery,
  useListClientsQuery,
} from "../gql/schema";
import SelectInput from "../Components/SelectInput";
import userContext from "../contexts/userContext";
import StatCard from "../Components/StatCard";
import { getDelta } from "../Components/GrowthComponent";
import { useNavigate } from "react-router-dom";

const Admin = () => {
  const { data } = useListClientsQuery();
  const [changeClient] = useChangeSellerClientMutation();
  const [currentClient, setCurrentClient] = React.useState<string>("");
  const { user } = React.useContext(userContext);
  const navigate = useNavigate();

  const { data: adminData, loading } = useGetAdminStatsQuery({
    variables: {
      timeFrame: "week",
    },
  });
  const { data: prevAdminData, loading: prevLoading } = useGetAdminStatsQuery({
    variables: {
      timeFrame: "week",
      offset: true,
    },
  });

  React.useEffect(() => {
    if (user?.seller?.client?.id) {
      setCurrentClient(user.seller?.client.id);
    }
  }, [user?.seller?.client?.id]);

  const clientOptions: { label: string; value: string }[] =
    data?.listClients
      ?.filter((i) => !!i?.name)
      ?.map((c) => ({
        value: c?.id,
        label: c!.name,
      })) || [];

  const updateCurrentClient = async (value: string) => {
    setCurrentClient(value);

    try {
      const response = await changeClient({
        variables: {
          clientId: value,
        },
      });
      if (!response?.data?.changeSellerClient?.seller?.id) {
        alert(response?.data?.changeSellerClient?.error?.message);
      }
    } catch (e) {
      alert(e);
    }
  };

  return (
    <Page>
      <Header title="Admin" />
      <div className="flex flex-col items-start">
        <h3 className="mb-4 font-semibold text-lg">Last 7 days</h3>
        <div className="flex mb-2 flex-row flex-wrap w-full">
          <StatCard
            title="Num. questions/day"
            number={adminData?.getAdminStats?.numQuestions}
            growth={getDelta(
              adminData?.getAdminStats?.numQuestions,
              prevAdminData?.getAdminStats?.numQuestions
            )}
            loading={loading || prevLoading}
            format="2dp"
            info="Total number of questions answered by all users per day"
          />
          <StatCard
            title="Answer accuracy"
            number={(adminData?.getAdminStats?.accuracyLevel || 0) * 100}
            growth={getDelta(
              adminData?.getAdminStats?.accuracyLevel,
              prevAdminData?.getAdminStats?.accuracyLevel
            )}
            loading={loading || prevLoading}
            format="%"
            info="Percent of questions not unknown or downvoted"
          />
          <StatCard
            title="Questions per user"
            number={adminData?.getAdminStats?.questionsPerUser}
            growth={getDelta(
              adminData?.getAdminStats?.questionsPerUser,
              prevAdminData?.getAdminStats?.questionsPerUser
            )}
            loading={loading || prevLoading}
            format="2dp"
            info="Number of questions asked by a user who asked at least one question"
          />
        </div>
        <div className="mb-4">
          <SelectInput
            label="Client"
            options={clientOptions}
            value={currentClient}
            onChange={updateCurrentClient}
          />
        </div>
        <h3 className="mb-4 font-semibold text-lg">
          Inaccurate replies in last 7 days
        </h3>

        <div className="w-3/4">
          {adminData?.getAdminStats?.recentMistakes?.map((msg, idx) => (
            <div
              key={idx}
              className="mb-2 text-gray-500 p-2 my-1 cursor-pointer hover:bg-ultra-light-yellow rounded-md w-full flex justify-between "
              onClick={() => {
                navigate(`/activity/${msg?.conversation?.id}`);
              }}
            >
              <div>{msg?.previousBuyerMessage?.body}</div>
              <div>{msg?.conversation?.client?.name}</div>
            </div>
          ))}
        </div>
      </div>
    </Page>
  );
};

export default Admin;
