import React from "react";

type LabelProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
  className?: string;
  children?: React.ReactNode;
};

const defaultStyle =
  "block mb-2 text-sm font-medium text-gray-900 dark:text-white";

const Label: React.FC<LabelProps> = ({ children, className, ...rest }) => (
  <label className={`${defaultStyle} ${className}`} {...rest}>
    {children}
  </label>
);

export default Label;
