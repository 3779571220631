import React from "react";
import { Link } from "react-router-dom";
import onboardingContext from "../contexts/onboardingContext";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FiMessageCircle,
  FiSettings,
  FiLogOut,
  FiTrello,
  FiMonitor,
  FiHardDrive,
  FiUser,
} from "react-icons/fi";
import SidebarItem from "./SidebarItem";
import userContext from "../contexts/userContext";
import authContext from "../contexts/authContext";
import ReactGA from "react-ga4";
import { CgGym } from "react-icons/cg";
import Popover, { PopoverChild } from "../Components/Popover";

const sidebarItems = [
  {
    name: "Overview",
    path: "/dashboard",
    icon: <FiTrello />,
  },

  // {
  //   name: "Views",
  //   path: "/views",
  //   icon: <FiMonitor />,
  // },
  {
    name: "Activity",
    path: "/activity",
    icon: <FiMessageCircle />,
  },
  {
    name: "Training",
    path: "/dojo",
    icon: <CgGym />,
  },
  {
    name: "Configuration",
    path: "/config",
    icon: <FiSettings />,
    disabled: true,
  },
  {
    name: "Admin",
    path: "/admin",
    icon: <FiHardDrive />,
    disabled: false,
  },
];

const Page = ({
  children,
  parentClass,
  childClass,
  showSidebar,
}: {
  children: React.ReactNode;
  parentClass?: string;
  childClass?: string;
  showSidebar?: boolean;
}) => {
  const { onboardingState } = React.useContext(onboardingContext);
  const location = useLocation();
  const navigate = useNavigate();
  const loggedIn = React.useMemo(
    () => onboardingState !== "email",
    [onboardingState]
  );
  const { user } = React.useContext(userContext);
  const firstLetter = React.useMemo(() => {
    const email = user?.email;
    if (!email) return null;
    return email[0].toUpperCase();
  }, [user]);

  const { signOut } = React.useContext(authContext);

  React.useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  return (
    <div className="page fixed w-full h-full ">
      <div className="main-logo absolute w-16 left-8 top-16 cursor-pointer z-10">
        <Link to="/">
          <img src={require("../images/logo.png")} alt="logo" />
        </Link>
      </div>

      {showSidebar !== false && onboardingState === "onboarded" ? (
        <div className="flex w-screen h-screen">
          <div className="sidebar h-full w-64 pt-40 pr-4 mr-8 pl-4 relative border-r ">
            {sidebarItems
              .filter((u, idx) =>
                !!user?.isAdmin ? true : idx < sidebarItems.length - 1
              )
              .map((item) => (
                <SidebarItem
                  {...item}
                  selected={
                    (location.pathname === "/" && item.path === "/") ||
                    (item.path !== "/" &&
                      location.pathname.startsWith(item.path))
                  }
                  onClick={() => navigate(item.path)}
                  key={item?.name}
                />
              ))}
            {loggedIn && firstLetter && (
              <div className="absolute bottom-20 left-8">
                <Popover
                  itemChild={
                    <div className="avatar absolute w-12 cursor-pointer h-12 bg-gray-100 rounded-full flex items-center justify-center hover:opacity-80">
                      <span className="text-2xl text-gray-500 font-semibold">
                        {firstLetter}
                      </span>
                    </div>
                  }
                  popoverChild={
                    <PopoverChild>
                      <div className="first:rounded-t-lg flex items-center p-2 px-6 text-gray-400">
                        <FiUser />
                        <div className="ml-2">{user?.email}</div>
                      </div>
                      <div
                        className="last:rounded-b-lg flex items-center p-2 px-6 cursor-pointer hover:bg-gray-100"
                        onClick={signOut}
                      >
                        <FiLogOut />
                        <div className="ml-2 text-gray-500">Log out</div>
                      </div>
                    </PopoverChild>
                  }
                />
              </div>
            )}
          </div>
          <div
            className={`flex-1 overflow-y-auto flex flex-col h-full justify-start container ${
              parentClass || ""
            }`}
          >
            <div className={`flex flex-col pb-24 pl-1 flex-1 ${childClass}`}>
              {children}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={`ml-72 overflow-y-auto flex-1 flex flex-col h-full justify-start container ${
            parentClass || ""
          }`}
        >
          <div
            className={`flex flex-col flex-1 max-w-4xl h-full mr-32 pb-24 ${
              childClass || ""
            }`}
          >
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default Page;
