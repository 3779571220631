import React from "react";
import ConnectionItem from "../Components/ConnectionItem";
import Header from "../Components/Header";
import Page from "./Page";
import LinkedInConnectionModal from "./LinkedInConnectionModal";
import Button from "../Components/Button";

const leadGenOptions = [
  {
    name: "Apollo",
    image: "apollo.png",
    disabled: true,
  },
  {
    name: "ZoomInfo",
    image: "zi.png",
    disabled: true,
  },
  {
    name: "LeadIQ",
    image: "leadiq.jpeg",
    disabled: true,
  },
  {
    name: "Cognism",
    image: "cognism.png",
    disabled: true,
  },
  {
    name: "Upload CSV",
    image: "csv.png",
  },
  { name: "LinkedIn", image: "linkedin.png", onClick: () => {} },
];

const crms = [
  {
    name: "Hubspot",
    image: "hs.webp",
    disabled: true,
  },
  {
    name: "Salesforce",
    image: "sf.png",
    disabled: true,
  },
  {
    name: "PipeDrive",
    image: "pd.jpeg",
    disabled: true,
  },
];

const emailClients = [
  {
    name: "Gmail",
    image: "gmail.png",
    disabled: true,
  },
  {
    name: "Outlook",
    image: "outlook.jpeg",
    disabled: true,
  },
];

const ConnectWith = () => {
  const [enabledList, setEnabledList] = React.useState<string[]>([]);
  const [modalOpen, setModalOpen] = React.useState<string | null>(null);

  const handleClick = (name: string) => {
    if (enabledList.includes(name)) {
      setEnabledList(enabledList.filter((item) => item !== name));
    } else {
      setEnabledList([...enabledList, name]);
      if (name === "LinkedIn") {
        setModalOpen("LinkedIn");
      }
    }
  };
  return (
    <Page childClass="w-4xl">
      <Header title="Connect with" />
      <LinkedInConnectionModal
        open={modalOpen === "LinkedIn"}
        onClose={() => setModalOpen(null)}
      />
      <div className="max-w-4xl">
        <div>
          <div className="font-semibold mb-1">Lead generation tools</div>

          <div className="flex flex-wrap mb-6">
            {leadGenOptions.map((connectionOption) => (
              <ConnectionItem
                name={connectionOption.name}
                image={connectionOption.image}
                selected={enabledList.includes(connectionOption.name)}
                onClick={() => handleClick(connectionOption.name)}
              />
            ))}
          </div>
        </div>
        <div>
          <div className="font-semibold mb-1">CRMs</div>

          <div className="flex flex-wrap mb-6">
            {crms.map((connectionOption) => (
              <ConnectionItem
                name={connectionOption.name}
                image={connectionOption.image}
                selected={enabledList.includes(connectionOption.name)}
                onClick={() => handleClick(connectionOption.name)}
              />
            ))}
          </div>
        </div>
        <div>
          <div className="font-semibold mb-1">Email clients</div>

          <div className="flex flex-wrap mb-6">
            {emailClients.map((connectionOption) => (
              <ConnectionItem
                name={connectionOption.name}
                image={connectionOption.image}
                selected={enabledList.includes(connectionOption.name)}
                onClick={() => handleClick(connectionOption.name)}
              />
            ))}
          </div>
        </div>
        <Button onClick={() => {}}>Continue</Button>
      </div>
    </Page>
  );
};

export default ConnectWith;
