import React from "react";
import "./App.css";
import {
  BrowserRouter,
  Route,
  Routes,
  useLocation,
  Navigate,
  RouteProps,
  Outlet,
} from "react-router-dom";
import Login from "./Login/Login";
import ConnectWith from "./Views/ConnectWith";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import onboardingContext, {
  OnboardingProvider,
} from "./contexts/onboardingContext";
import { UserProvider } from "./contexts/userContext";
import authContext from "./contexts/authContext";
import Overview from "./Views/Overview";
import ActivityList from "./Views/ActivityList";
import Activity from "./Views/Activity";
import ViewsList from "./Views/ViewsList";
import View from "./Views/View";
import { ToastContainer } from "react-toastify";
import { FiX } from "react-icons/fi";
import ChromeExtension from "./Views/ChromeExtension";
import Admin from "./Views/Admin";
import Dojo from "./Views/Dojo";
import TokenCheck from "./Views/TokenCheck";
import ResetPassword from "./Login/ResetPassword";
import CheckUser from "./Views/CheckUser";

const NoMatch = () => {
  return <div>404</div>;
};

const RequireAuth = () => {
  let { authToken } = React.useContext(authContext);
  let location = useLocation();
  const { setOnboardingState } = React.useContext(onboardingContext);
  const client = React.useMemo(
    () =>
      new ApolloClient({
        uri: `${process.env.REACT_APP_API_ROOT}/api/graphql/`,
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
        cache: new InMemoryCache(),
      }),
    [authToken]
  );

  if (!authToken) {
    setOnboardingState("email");
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return (
    <ApolloProvider client={client}>
      <UserProvider>
        <Outlet />
      </UserProvider>
    </ApolloProvider>
  );
};

const AlreadyLoggedIn = () => {
  let { authToken } = React.useContext(authContext);
  let location = useLocation();

  if (authToken) {
    return <Navigate to="/" state={{ from: location }} replace />;
  }

  return <Outlet />;
};

function App() {
  return (
    <OnboardingProvider>
      <BrowserRouter>
        <Routes>
          <Route element={<RequireAuth />}>
            <Route path="/chrome-extension" element={<ChromeExtension />} />
            <Route
              path="/activity/:id/messages/:messageId"
              element={<Activity />}
            />
            <Route path="/activity/:id" element={<Activity />} />
            <Route path="/activity" element={<ActivityList />} />
            <Route path="/demos/:demoId/views/:id" element={<View />} />
            <Route path="/views" element={<ViewsList />} />
            <Route path="/integrations" element={<ConnectWith />} />
            <Route path="/activity" element={<ActivityList />} />
            <Route path="/admin" element={<Admin />} />
            <Route path="/dojo" element={<Dojo />} />
            <Route path="/token-check" element={<TokenCheck />} />

            <Route path="/" element={<CheckUser />} />
            <Route path="/dashboard" element={<Overview />} />
            <Route path="*" element={<Overview />} />
          </Route>
          <Route path="/login" element={<Login />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route element={<AlreadyLoggedIn />}>
            {/* <Route path="/sign-up" element={<Login />} /> */}
          </Route>
        </Routes>
      </BrowserRouter>
      <ToastContainer
        position="bottom-center"
        hideProgressBar
        autoClose={5000}
        newestOnTop={true}
        closeOnClick
        pauseOnHover
        pauseOnFocusLoss
        theme="light"
        draggable={false}
        closeButton={<FiX size={20} />}
        style={{
          padding: "12px",
          borderRadius: "12px",
          maxWidth: "none", // Add this line to remove width limit
        }}
      />
    </OnboardingProvider>
  );
}

export default App;
