import React from "react";

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  className?: string; // Just an example of a custom prop
  variant?: "contained" | "outlined" | "text";
};

const containedStyle =
  "flex items-center text-gray-900 bg-main-yellow disabled:bg-mid-yellow active:opacity-90 disabled:text-gray-400 focus:outline-none hover:bg-dark-yellow focus:ring-gray-200 font-medium rounded-lg text-sm px-4 py-1.5 mr-4 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700";

const outlinedStyle =
  "flex items-center active:bg-gray-100 text-gray-900 disabled:text-gray-400 disabled:bg-gray-50 disabled:border-gray-200 bg-white border border-gray-300 focus:outline-none hover:bg-gray-50 font-medium rounded-lg text-sm px-4 py-1.5 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-600 dark:focus:ring-gray-700";

const Button: React.FC<ButtonProps> = ({
  children,
  variant = "contained",
  className,
  ...rest
}) => (
  <button
    type="button"
    className={`button ${
      variant === "contained" ? containedStyle : outlinedStyle
    } ${className}`}
    {...rest}
  >
    {children}
  </button>
);

export default Button;
