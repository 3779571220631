import React from "react";

type TextInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  className?: string;
};

const defaultStyle =
  "focus:border-white focus:ring-0 bg-gray-50 border border-gray-300 disabled:border-gray-200  text-gray-900 text-sm rounded-lg focus:outline-main-yellow focus:border-main-yellow block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 disabled:text-gray-400";

const TextInput: React.FC<TextInputProps> = ({ className, ...rest }) => (
  <input type="text" className={`${defaultStyle} ${className}`} {...rest} />
);

export default TextInput;
