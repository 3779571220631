import React, { useContext } from "react";
import userContext from "../contexts/userContext";
import { useNavigate } from "react-router-dom";

const CheckUser = () => {
  const { user, userLoading } = useContext(userContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!userLoading && !user) {
      navigate("/login");
    } else if (
      !userLoading &&
      user &&
      !(user.seller.client.plan === "testing" && !user.isAdmin)
    ) {
      navigate("/dashboard");
    }
  }, [user, userLoading]);

  return null;
};

export default CheckUser;
