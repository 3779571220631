import React from "react";
import ReactDOM from "react-dom";

type Props = {
  itemChild: React.ReactNode;
  popoverChild: React.ReactNode;
};

type ChildProps = {
  className?: string;
  text: string;
  style?: React.CSSProperties;
};

export const TooltipChild = (props: ChildProps) => {
  return props.text ? (
    <div
      className={`bg-gray-700 text-sm text-white rounded-lg drop-shadow shadow p-2 relative ${
        props.className || ""
      }`}
      style={props.style}
    >
      {props.text}
    </div>
  ) : null;
};

const Tooltip = (props: Props) => {
  const [open, setOpen] = React.useState(false);
  const tooltipPopupRef = React.useRef<HTMLDivElement>(null);
  const tooltipTriggerRef = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    if (!tooltipTriggerRef.current || !tooltipPopupRef.current) return;

    // Ensure the tooltip is visible before calculating dimensions
    tooltipPopupRef.current.style.visibility = "hidden";
    tooltipPopupRef.current.style.display = "block";

    const triggerRect = tooltipTriggerRef.current.getBoundingClientRect();
    const tooltipRect = tooltipPopupRef.current.getBoundingClientRect();

    const viewportWidth = window.innerWidth;
    const margin = 12; // Margin from the edge of the window

    let left =
      triggerRect.left +
      window.scrollX +
      triggerRect.width / 2 -
      tooltipRect.width / 2;

    // Adjust left position if the tooltip is going off the right edge of the screen
    if (left + tooltipRect.width > viewportWidth - margin) {
      left = viewportWidth - tooltipRect.width - margin;
    }

    // Adjust left position if the tooltip is going off the left edge of the screen
    if (left < margin) {
      left = margin;
    }

    const top = triggerRect.top + window.scrollY - tooltipRect.height - margin;

    tooltipPopupRef.current.style.top = `${top}px`;
    tooltipPopupRef.current.style.left = `${left}px`;

    // Make the tooltip visible after positioning
    tooltipPopupRef.current.style.visibility = "visible";
    tooltipPopupRef.current.style.display = open ? "block" : "none";
  }, [open]);

  return (
    <div className="relative">
      <div
        className="focus:outline-none cursor-pointer"
        onMouseEnter={() => setOpen(true)}
        onMouseLeave={() => setOpen(false)}
        ref={tooltipTriggerRef}
      >
        {props.itemChild}
      </div>
      {ReactDOM.createPortal(
        <div
          className={`absolute z-10 max-w-md pointer-events-none ${
            open ? "block" : "hidden"
          }`}
          style={{ position: "fixed" }} // Use fixed positioning
          ref={tooltipPopupRef}
        >
          {props.popoverChild}
        </div>,
        document.body
      )}
    </div>
  );
};
export default Tooltip;
