import React from "react";
import useTextLocation from "./useTextLocation";

type Props = {
  textLocation: {
    height: number;
    width: number;
    x: number;
    y: number;
  } | null;
};

const imageHeight = 533;

export type TextLocationType = null | {
  arrow: React.CSSProperties;
  text: React.CSSProperties;
};

const useScreenAdjustedTextLocation = ({
  textLocation,
}: Props): TextLocationType => {
  const [annotationPosition, setAnnotationPosition] =
    React.useState<TextLocationType | null>(null);

  const startingAnnotationPosition = useTextLocation({
    mouseCoordinates: textLocation,
  });

  React.useEffect(() => {
    if (!startingAnnotationPosition || !textLocation) return;

    const imageElement = document.querySelector("#telepathic-image"); // Adjust selector as needed
    if (imageElement) {
      const imageRect = imageElement.getBoundingClientRect();
      const newPosition: {
        arrow: React.CSSProperties;
        text: React.CSSProperties;
      } = startingAnnotationPosition;

      ["left", "right", "top", "bottom"].forEach((prop) => {
        const arrowProp = prop as keyof React.CSSProperties;
        const textProp = prop as keyof React.CSSProperties;

        const arrowPositionValue =
          parseFloat(startingAnnotationPosition.arrow[arrowProp] as any) || 0;
        const textPositionValue =
          parseFloat(startingAnnotationPosition.text[textProp] as any) || 0;
        // Adjust the arrow position
        if (startingAnnotationPosition.arrow[arrowProp] !== undefined) {
          if (arrowProp === "left") {
            newPosition.arrow[arrowProp] =
              imageRect["left"] + window.scrollX + arrowPositionValue;
          } else if (arrowProp === "right") {
            newPosition.arrow[arrowProp] =
              window.innerWidth -
              imageRect["right"] +
              window.scrollX +
              arrowPositionValue;
          } else if (arrowProp === "bottom") {
            newPosition.arrow[arrowProp] =
              window.innerHeight -
              imageRect[arrowProp] +
              window.scrollY +
              arrowPositionValue;
          } else if (arrowProp === "top") {
            newPosition.arrow[arrowProp] =
              imageRect[arrowProp] + window.scrollY + arrowPositionValue;
          }
        }

        // Adjust the text position
        if (startingAnnotationPosition.text[textProp] !== undefined) {
          if (textProp === "left") {
            newPosition.text[textProp] =
              imageRect["left"] + window.scrollX + textPositionValue;
          } else if (textProp === "right") {
            newPosition.text[textProp] =
              window.innerWidth -
              imageRect["right"] +
              window.scrollX +
              textPositionValue;
          } else if (textProp === "bottom") {
            // 'top' or 'bottom'
            newPosition.text[textProp] =
              window.innerHeight -
              imageRect[textProp] +
              window.scrollY +
              textPositionValue;
          } else if (textProp === "top") {
            // 'top' or 'bottom'
            newPosition.text[textProp] =
              imageRect[textProp] + window.scrollY + textPositionValue;
          }
        }
      });

      setAnnotationPosition(newPosition);
    }
  }, [startingAnnotationPosition, textLocation]);

  return annotationPosition;
};

export default useScreenAdjustedTextLocation;
