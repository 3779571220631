import React, { useContext } from "react";
import userContext from "../contexts/userContext";
import useTestingRedirect from "../hooks/useTestingRedirect";

const TokenCheck = () => {
  const { user } = useContext(userContext);
  useTestingRedirect(user, true);

  return null;
};

export default TokenCheck;
