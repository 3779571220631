import React from "react";
import { UserType } from "../contexts/userContext";

const useTestingRedirect = (user: UserType, shouldCheck: boolean) => {
  React.useEffect(() => {
    if (shouldCheck && user?.seller?.client?.id) {
      const authToken = window.localStorage.getItem("__A__");
      const newUrl =
        process.env.REACT_APP_TESTING_ROOT +
          "/accounts/" +
          user.seller.client.id +
          "/?sellerToken=" +
          authToken +
          "&userEmail=" +
          user.email || user?.seller?.email;
      window.location.href = newUrl;
    }
  }, [user, shouldCheck]);

  return;
};

export default useTestingRedirect;
