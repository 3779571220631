import React from "react";

type Props = {
  id: string;
  title: string;
  image: string;
  onClick: () => void;
  favorite?: boolean;
  onFavoriteClick: () => void;
  archived?: boolean;
};

const ViewPreview = (props: Props) => {
  return (
    <div
      className="flex flex-col justify-end rounded-md border border-gray-300 h-48 w-64 cursor-pointer mr-6 mb-4"
      onClick={props.onClick}
    >
      <img
        src={props.image}
        className="rounded-t-md object-fill border-b border-gray-300"
        style={{ minHeight: 0 }}
      />
      <div
        className="px-2 py-1 whitespace-nowrap overflow-ellipsis overflow-hidden text-gray-700 flex-shrink-0"
        style={{ minHeight: 32 }}
      >
        {props.title}
      </div>
    </div>
  );
};

export default ViewPreview;
