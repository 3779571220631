import React from "react";

type Props = {
  children: React.ReactNode;
  className?: string;
  style?: React.CSSProperties;
};

const Card = (props: Props) => {
  return (
    <div
      className={`p-4 rounded-xl border border-gray-300 mr-4 mb-4 ${
        props.className || ""
      }`}
      style={props.style || {}}
    >
      {props.children}
    </div>
  );
};

export default Card;
