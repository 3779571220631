import React from "react";

import { FiCornerDownRight } from "react-icons/fi";

const Prompts = ({ prompts }: { prompts: string[] }) => {
  return (
    prompts.length > 0 && (
      <div className="prompts-container flex flex-col items-start">
        <div className="text-sm text-gray-400 mb-1 mt-3">FAQs</div>

        {prompts?.map((prompt, idx) => (
          <div
            key={idx}
            className={`border-2 p-2 rounded-lg text-sm font-light mr-2 px-3 bg-white text-black mb-2 `}
            style={{}}
          >
            <FiCornerDownRight className="inline mr-2 color-darker-primary" />
            {prompt}
          </div>
        ))}
      </div>
    )
  );
};

export default Prompts;
