export const scoreToColor = (leadScore?: number | null) => {
  if (leadScore === null || leadScore === undefined) {
    return "text-gray-500";
  } else if (leadScore <= 33) {
    return "text-failure-red";
  } else if (leadScore <= 66) {
    return "text-warning-orange";
  } else {
    return "text-success-green";
  }
};

export const scoreToColorHtml = (leadScore: number) => {
  if (leadScore <= 33) {
    return "#B62C31";
  } else if (leadScore <= 66) {
    return "#DB6D4C";
  } else {
    return "#5F9155";
  }
};

export const leadScoreToWords = (leadScore: number) => {
  if (leadScore <= 33) {
    return "Not great";
  } else if (leadScore <= 66) {
    return "Ok!";
  } else if (leadScore <= 90) {
    return "Good!";
  } else {
    return "Very good!";
  }
};

export const cleanUrl = (url: string) => {
  // Ensure the URL starts with 'https://'
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    url = "https://" + url;
  }

  // Ensure the URL contains 'www.' after the scheme
  if (!url.includes("://www.")) {
    url = url.replace("://", "://www.");
  }
  return url;
};

export const getDirectUrl = (
  currentUrl: string,
  destinationUrl: string,
  trainingOrigin?: string,
  hasImage?: boolean
): string => {
  let returnUrl = destinationUrl;

  // if (!!trainingOrigin && destinationUrl.includes(trainingOrigin)) {
  //   const currentUrlObj = new URL(currentUrl);
  //   const currentOrigin = currentUrlObj.origin;
  //   const destinationUrlObj = new URL(destinationUrl);

  //   // return the destination url but with the training origin instead of the destination origin
  //   returnUrl = destinationUrl.replace(destinationUrlObj.origin, currentOrigin);
  // }
  if (hasImage) {
    // Simplify the URL by removing UUID or ID and the remainder of the string if present
    const uuidRegex =
      /\/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}.*$/;
    const idRegex = /\/\d+.*$/;
    returnUrl = returnUrl.replace(uuidRegex, "").replace(idRegex, "");
  }

  return returnUrl;
};
