import React, { useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Header from "../Components/Header";
import Page from "./Page";
import ConversationReviewScreen from "./ConversationReviewScreen";
import ActivityTimeline, { TimelineItem } from "../Components/ActivityTimeline";
import SearchBar from "../Components/SearchBar";
import Prompts from "../Components/Prompts";
import { useGetConversationQuery } from "../gql/schema";
import { DateTime } from "luxon";
import userContext from "../contexts/userContext";

type SampleMessage = {
  id: string;
  createdAt?: string;
  messageViews: {
    view: {
      image: string | null;
      url: string;
      promptText: string;
      clickEvent: string;
    };
  }[];
  body?: string;
  sender?: string;
  sentiment?: string | null;
  feedback: {
    positive: boolean;
    text: string;
  }[];
};

const sampleMessages: SampleMessage[] = [
  {
    id: "1",
    createdAt: "2022-10-15T08:00:00",
    messageViews: [
      {
        view: {
          image: null,
          url: "https://example.com/michael",
          promptText:
            "How do I invite a colleague?|How can I list my colleagues?|What is the process to add a new team member?",
          clickEvent: '{"x": 100, "y": 50, "width": 200, "height": 150}',
        },
      },
    ],
    body: "How do I export my colleagues list?",
    sender: "BY",
    sentiment: "positive",
    feedback: [],
  },
  {
    id: "2",
    createdAt: "2022-10-15T08:05:00",
    messageViews: [
      {
        view: {
          image:
            "https://telepathic-static.s3.eu-west-2.amazonaws.com/general/amplitude-invite-page.jpeg",
          url: "https://app.amplitude.com/analytics/demo/settings/users/joined",
          promptText:
            "How do I create a new identity?|What are the steps to invite a new member?",
          clickEvent: '{"x": 1330, "y": 20, "width": 180, "height": 120}',
        },
      },
    ],
    body: "You can export this list by clicking on the 'Export CSV' button on the top right corner of the page.",
    sender: "CB",
    sentiment: null,
    feedback: [
      {
        positive: true,
        text: "Great response, very helpful!",
      },
    ],
  },
];

const Activity = () => {
  const { id, messageId } = useParams();
  const navigate = useNavigate();
  const [showAnnotations, setShowAnnotations] = React.useState(true);
  const { user } = useContext(userContext);
  const isSampleData =
    user?.seller?.client?.name === "Telepathic" || id === "sample";

  const { data } = useGetConversationQuery({
    variables: { id: id! },
    skip: id === "sample",
  });

  const sortedMessages = React.useMemo(
    () =>
      isSampleData
        ? sampleMessages
        : data?.getConversation?.messages
            .slice()
            .sort(
              (a, b) =>
                new Date(a.createdAt).getTime() -
                new Date(b.createdAt).getTime()
            ) || [],
    [data, isSampleData]
  );

  const formattedMessages: TimelineItem[] = React.useMemo(
    () =>
      sortedMessages.map((msg, idx) => ({
        ...msg,
        createdAt: DateTime.fromISO(msg.createdAt),
        body: msg.body || "",
        sender: msg.sender,
        sentiment: msg.sentiment,
        type:
          msg.sender === "BY"
            ? "search"
            : (idx > 0 && sortedMessages[idx - 1].sender === "CB") ||
              (idx + 1 < sortedMessages.length &&
                sortedMessages[idx + 1].sender === "CB")
            ? "reply-sequence"
            : msg?.messageViews?.filter((mv) => !!mv?.view?.image)?.length > 0
            ? "reply-single"
            : "reply-text",
        feedback: msg?.feedback,
      })),
    [sortedMessages]
  );

  const initialStepIdx = messageId
    ? sortedMessages.findIndex((msg) => msg.id === messageId)
    : 0;
  const [stepIdx, setStepIdx] = React.useState(initialStepIdx);

  const currentMessage = React.useMemo(() => {
    return sortedMessages[stepIdx];
  }, [stepIdx, sortedMessages]);

  const currentView = React.useMemo(() => {
    if ((currentMessage?.messageViews || []).length > 0) {
      return currentMessage?.messageViews[0]?.view || null;
    } else {
      return null;
    }
  }, [currentMessage]);

  const clickLocation = React.useMemo(() => {
    if (currentView?.clickEvent) {
      const event = JSON.parse(currentView.clickEvent);
      return {
        x: event.x,
        y: event.y,
        width: event.width,
        height: event.height,
      };
    } else {
      return null;
    }
  }, [currentView]);

  React.useEffect(() => {
    if (messageId !== sortedMessages[stepIdx]?.id) {
      if (!!sortedMessages[stepIdx]?.id) {
        navigate(`/activity/${id}/messages/${sortedMessages[stepIdx]?.id}`);
      } else {
        setStepIdx(
          sortedMessages.findIndex((msg) => msg.id === messageId) || 0
        );
      }
    }
  }, [stepIdx, sortedMessages, id, navigate, messageId]);

  return (
    <Page
      childClass="max-w-7xl !pb-0 !overflow-hidden"
      parentClass="!overflow-hidden"
    >
      <Header title="Activity" />
      <div className="flex w-full flex-1 overflow-auto">
        <div className="large-left" style={{ width: 800 }}>
          <ConversationReviewScreen
            imageUrl={currentView?.image || null}
            text={
              currentMessage?.sender === "CB"
                ? currentMessage?.body || null
                : null
            }
            textLocation={clickLocation}
            showAnnotations={showAnnotations}
          />
          <SearchBar
            text={
              currentMessage?.sender === "BY"
                ? currentMessage?.body || null
                : null
            }
            url={currentView?.url || null}
            imageUrl={currentView?.image || null}
          />
          <Prompts
            prompts={
              currentView?.promptText?.split(/\n|\|/)?.map((p) => p?.trim()) ||
              []
            }
          />
        </div>
        <ActivityTimeline
          stepIdx={stepIdx}
          setStepIdx={setStepIdx}
          messages={formattedMessages}
          setShowAnnotations={setShowAnnotations}
          showAnnotations={showAnnotations}
          buyerEmail={
            data?.getConversation?.buyer?.encryptedEmail ||
            data?.getConversation?.buyer?.user?.email ||
            ""
          }
          numUserConversations={
            data?.getConversation?.buyer?.conversations?.length || 0
          }
        />
      </div>
    </Page>
  );
};

export default Activity;
