import React from "react";

type Props = {
  number: number;
  className?: string;
};

const SentimentIcon = (props: Props) => {
  return (
    <div className={`flex justify-start ${props.className || ""}`}>
      <div className={`flex `}>
        {props.number === 0 ? (
          <div className={`w-2 h-2 rounded-full bg-gray-500`}></div>
        ) : (
          Array.from(Array(Math.abs(props.number)), (e, i) => {
            return (
              <div
                key={i}
                className={`w-2 h-2 rounded-full ${
                  props.number >= 0 ? `bg-green-500 mr-1` : `bg-red-500 mr-1`
                }`}
              ></div>
            );
          })
        )}
      </div>
    </div>
  );
};

export default SentimentIcon;
