import React, { useEffect } from "react";
import Button from "./Button";
import {
  FiAlignLeft,
  FiChevronLeft,
  FiChevronRight,
  FiEye,
  FiEyeOff,
  FiImage,
  FiInfo,
  FiNavigation,
  FiSearch,
  FiThumbsDown,
  FiThumbsUp,
} from "react-icons/fi";
import { DateTime } from "luxon";
import Tooltip, { TooltipChild } from "./Tooltip";
import IconButton from "./IconButton";
import { CgGym } from "react-icons/cg";
import SentimentIcon from "./SentimentIcon";
import Pill from "./Pill";
import { useParams } from "react-router-dom";

export type TimelineItem = {
  id: string;
  type:
    | "search"
    | "reply-text"
    | "reply-single"
    | "reply-sequence"
    | "feedback";
  createdAt: DateTime;
  body: string;
  sender?: string | null;
  sentiment?: string | null;
  feedback?: {
    positive: boolean;
    text?: string | null;
  }[];
};

type Props = {
  stepIdx: number;
  setStepIdx: (idx: number) => void;
  messages: TimelineItem[];
  showAnnotations?: boolean;
  setShowAnnotations: (show: boolean) => void;
  buyerEmail?: string;
  numUserConversations?: number;
};

const ActivityTimeline = (props: Props) => {
  const { id } = useParams();
  const refs = React.useRef<Array<HTMLLIElement | null>>([]);
  React.useEffect(() => {
    // Ensure the current step index is scrolled into view
    if (refs.current[props.stepIdx]) {
      refs.current[props.stepIdx]?.scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  }, [props.stepIdx]);

  const lastUserMessage = React.useMemo(() => {
    // We start from the stepIdx and go backwards to find the last message from "BY"
    for (let i = props.stepIdx; i >= 0; i--) {
      if (!!props.messages[i] && props.messages[i].sender === "BY") {
        return props.messages[i]; // Return the first "BY" message found when iterating backwards
      }
    }
    return null; // Return null if no message is found
  }, [props.messages, props.stepIdx]);

  const handleRightClick = () => {
    if (props.stepIdx < props.messages.length - 1) {
      props.setStepIdx(props.stepIdx + 1);
    }
  };

  const handleLeftClick = () => {
    if (props.stepIdx > 0) {
      props.setStepIdx(props.stepIdx - 1);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === "ArrowRight") {
        handleRightClick();
      } else if (e.key === "ArrowLeft") {
        handleLeftClick();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [props.stepIdx, props.messages.length, props.setStepIdx]);

  return (
    <div className="w-56 ml-8 flex flex-col flex-1 mr-8">
      {props.buyerEmail && (
        <div className="flex items-center justify-start w-full mb-2">
          <div className="rounded-full bg-gray-100 p-1 h-12 w-12 text-xl flex items-center justify-center">
            {props.buyerEmail?.charAt(0).toUpperCase()}
          </div>
          <div className="ml-2">
            <div className="text-base">{props.buyerEmail}</div>
            {props.numUserConversations && (
              <div className="text-gray-500 text-sm leading-3">
                {props.numUserConversations} search session
                {props.numUserConversations > 1 ? "s" : ""}
              </div>
            )}
          </div>
        </div>
      )}
      <div className="flex justify-between pb-3 border-b flex-wrap">
        <div className="flex">
          <Button
            variant="outlined"
            className="mr-2"
            disabled={props.stepIdx === 0}
            onClick={() => props.setStepIdx(props.stepIdx - 1)}
          >
            <FiChevronLeft className="mr-1" />
            Previous
          </Button>
          <Button
            variant="outlined"
            className="mr-4"
            disabled={props.stepIdx === props.messages.length - 1}
            onClick={() => props.setStepIdx(props.stepIdx + 1)}
          >
            Next
            <FiChevronRight className="ml-1" />
          </Button>
          <div className="text-gray-500 flex items-center justify-center">
            {props.stepIdx + 1}/{props.messages.length}
          </div>
        </div>
        <div className="flex items-center justify-center my-2">
          <Tooltip
            itemChild={
              <IconButton
                onClick={() => {
                  props.setShowAnnotations(!props.showAnnotations);
                }}
              >
                {props.showAnnotations ? <FiEyeOff /> : <FiEye />}
              </IconButton>
            }
            popoverChild={
              <TooltipChild
                text={`${
                  props.showAnnotations ? "Don't overlay" : "Overlay"
                } views with text & arrow`}
              />
            }
          />
          <Tooltip
            itemChild={
              <a
                href={`/dojo?training=${encodeURIComponent(
                  'If the user asks "' +
                    (lastUserMessage?.body || "") +
                    '" then...'
                )}`}
              >
                <IconButton onClick={() => {}} className="ml-1">
                  <CgGym />
                </IconButton>
              </a>
            }
            popoverChild={<TooltipChild text="Improve this answer" />}
          />
        </div>
      </div>
      <div className="flex flex-col flex-1 pt-4 pl-2 overflow-auto">
        {id === "sample" && (
          <div className="flex flex-start mb-4">
            <div>
              <Tooltip
                itemChild={
                  <Pill
                    mainColor="text-link-blue"
                    text="Sample data"
                    className="bg-link-blue/20"
                    icon={<FiInfo />}
                  />
                }
                popoverChild={
                  <TooltipChild text="Here's a sample based on the analytics software Amplitude" />
                }
              />
            </div>
          </div>
        )}
        <ol className="relative border-s border-gray-200 dark:border-gray-700 ml-2 mb-16">
          {props.messages.map((t, idx) => (
            <li
              ref={(el) => (refs.current[idx] = el)}
              className={`${t?.id} mb-10 group ms-6 ${
                props.stepIdx === idx ? "text-black" : "text-gray-500"
              } cursor-pointer `}
              key={t?.id}
              onClick={() => props.setStepIdx(idx)}
            >
              <div className="absolute -start-3">
                <Tooltip
                  itemChild={
                    <span
                      className={` flex items-center justify-center w-6 h-6 ${
                        t?.type === "search"
                          ? "bg-blue-100 group-hover:bg-link-blue"
                          : "bg-light-yellow group-hover:bg-main-yellow"
                      } rounded-full ring-8 text-black ring-white dark:ring-gray-900 dark:bg-blue-900 ${
                        props.stepIdx === idx
                          ? t?.type === "search"
                            ? "!bg-link-blue"
                            : "!bg-main-yellow"
                          : ""
                      }`}
                    >
                      {t.type === "search" ? (
                        <FiSearch size="12" />
                      ) : t.type === "reply-text" ? (
                        <FiAlignLeft size="12" />
                      ) : t.type === "reply-single" ? (
                        <FiImage size="12" />
                      ) : t.type === "reply-sequence" ? (
                        <FiNavigation size="12" />
                      ) : null}
                    </span>
                  }
                  popoverChild={
                    <TooltipChild
                      text={
                        t?.type === "search"
                          ? "User: question asked"
                          : t?.type === "reply-text"
                          ? "AI: text response"
                          : t?.type === "reply-single"
                          ? "AI: single view response"
                          : t?.type === "reply-sequence"
                          ? "AI: multi-step response "
                          : "User: feedback given"
                      }
                    />
                  }
                />
              </div>
              <p className=" text-base font-normal dark:text-gray-400">
                {t?.body.length > 50 ? t?.body.slice(0, 50) + "..." : t?.body}
              </p>
              <div className="flex items-center mb-2 ">
                <Tooltip
                  itemChild={
                    <time
                      className={`block text-sm font-normal leading-none ${
                        props.stepIdx === idx ? "text-black" : "text-gray-400"
                      } mr-1`}
                    >
                      {t?.createdAt.toLocaleString(DateTime.TIME_SIMPLE)}
                    </time>
                  }
                  popoverChild={
                    <TooltipChild
                      text={t?.createdAt.toLocaleString(DateTime.DATETIME_FULL)}
                    />
                  }
                />

                {t?.sender === "BY" && (
                  <SentimentIcon
                    number={
                      t?.sentiment === "POSITIVE"
                        ? 1
                        : t?.sentiment === "NEGATIVE"
                        ? -1
                        : 0
                    }
                    className="flex-1 ml-1"
                  />
                )}
                <div className="flex items-center justify-start">
                  {t?.feedback?.map((f) => {
                    const icon = f?.positive ? (
                      <FiThumbsUp
                        size={12}
                        className="text-success-green ml-1"
                      />
                    ) : (
                      <FiThumbsDown
                        size={12}
                        className="text-failure-red ml-1"
                      />
                    );
                    return !!f?.text ? (
                      <Tooltip
                        itemChild={icon}
                        popoverChild={<TooltipChild text={f.text} />}
                      />
                    ) : (
                      icon
                    );
                  })}
                </div>
              </div>
            </li>
          ))}
        </ol>
      </div>
    </div>
  );
};

export default ActivityTimeline;
