import React from "react";
const AUTH_STORAGE_KEY = "__A__";

type AuthContext = {
  authToken: string | null;
  setAuthToken: (token: string | null) => void;
  signOut: () => void;
};

const authContext = React.createContext<AuthContext>({
  authToken: null,
  signOut: () => {},
  setAuthToken: () => {},
});

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const currentAuth = localStorage.getItem(AUTH_STORAGE_KEY);
  const [authToken, setAuthToken] = React.useState(currentAuth);

  const setAuthTokenFunc = React.useCallback(
    (token: string | null) => {
      if (!token) {
        localStorage.removeItem(AUTH_STORAGE_KEY);
        setAuthToken(null);
        return;
      }
      localStorage.setItem(AUTH_STORAGE_KEY, token);
      setAuthToken(token);
    },
    [setAuthToken]
  );

  const signOut = React.useCallback(() => {
    setAuthTokenFunc(null);
  }, [setAuthTokenFunc]);

  return (
    <authContext.Provider
      value={{ authToken, setAuthToken: setAuthTokenFunc, signOut }}
    >
      {children}
    </authContext.Provider>
  );
};

export default authContext;
