import React from "react";

type Props = {
  mainColor: string;
  text: string;
  icon?: React.ReactNode;
  className?: string;
};

const Pill = (props: Props) => {
  return (
    <div
      className={`flex items-center rounded-full px-3 py-1 text-sm ${props.mainColor} ${props.className}`}
    >
      {props.text}
      {props.icon && <span className="ml-1">{props.icon}</span>}
    </div>
  );
};

export default Pill;
