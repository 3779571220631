import React from "react";
import { FiCheck } from "react-icons/fi";

type Props = {
  image: string;
  name: string;
  selected: boolean;
  onClick: () => void;
};

const ConnectionItem = (props: Props) => {
  return (
    <div
      className="border flex mr-4 mb-2 p-3 rounded-xl w-64 justify-between items-center cursor-pointer"
      onClick={props.onClick}
    >
      <div className="flex items-center">
        <div
          className={`hover:bg-gray-100 border text-success-green rounded-full radio-button w-4 h-4 border-gray-300 focus:ring-2 focus:bg-main-yellow focus:ring-main-yellow dark:focus:ring-blue-600 dark:focus:bg-main-yellow dark:bg-gray-700 dark:border-gray-600`}
        >
          {props.selected ? <FiCheck fontSize={14} /> : null}
        </div>
        <div className="ml-2 text-sm font-medium text-gray-900 dark:text-white">
          {props.name}
        </div>
      </div>
      <img
        src={require(`../images/${props.image}`)}
        alt="profile"
        className="w-6 h-6 rounded-full ml-2 object-cover"
      />
    </div>
  );
};

export default ConnectionItem;
