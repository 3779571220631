import React, { useContext } from "react";
import Header from "../Components/Header";
import Page from "./Page";
import {
  FiArrowLeft,
  FiArrowRight,
  FiExternalLink,
  FiInfo,
  FiThumbsDown,
  FiThumbsUp,
} from "react-icons/fi";
import { useListConversationsQuery } from "../gql/schema";
import { DateTime } from "luxon";
import SentimentIcon from "../Components/SentimentIcon";
import userContext from "../contexts/userContext";
import Pill from "../Components/Pill";
import Tooltip, { TooltipChild } from "../Components/Tooltip";
import Table from "../Components/Table";

const sampleConversations = [
  {
    id: "sample",
    date: "2024-03-13T08:00:00.000Z",
    email: (
      <a
        href={`/activity/sample`}
        className="hover:underline flex items-center cursor-pointer"
      >
        <FiExternalLink className="mr-2" />
        dwight@dundermifflin.com
      </a>
    ),
    messages: 3,
    netSentiment: (
      <div className="whitespace-nowrap text-ellipsis overflow-hidden">
        <SentimentIcon number={1} className="flex-1" />
      </div>
    ),
    feedback: (
      <div className="flex text-left">
        <FiThumbsUp className="text-success-green mr-1" />
      </div>
    ),
  },
  {
    id: "sample",
    date: "2024-03-12T09:00:00.000Z",
    email: (
      <a
        href={`/activity/sample`}
        className="hover:underline flex items-center cursor-pointer"
      >
        <FiExternalLink className="mr-2" />
        jim@dundermifflin.com
      </a>
    ),
    messages: 4,
    netSentiment: (
      <div className="whitespace-nowrap text-ellipsis overflow-hidden">
        <SentimentIcon number={2} className="flex-1" />
      </div>
    ),
    feedback: (
      <div className="flex text-left">
        <FiThumbsUp className="text-success-green mr-1" />
      </div>
    ),
  },
  {
    id: "sample",
    date: "2024-03-11T10:00:00.000Z",
    email: (
      <a
        href={`/activity/sample`}
        className="hover:underline flex items-center cursor-pointer"
      >
        <FiExternalLink className="mr-2" />
        pam@dundermifflin.com
      </a>
    ),
    messages: 5,
    netSentiment: (
      <div className="whitespace-nowrap text-ellipsis overflow-hidden">
        <SentimentIcon number={3} className="flex-1" />
      </div>
    ),
    feedback: (
      <div className="flex text-left">
        <FiThumbsUp className="text-success-green mr-1" />
        <FiThumbsUp className="text-success-green mr-1" />
      </div>
    ),
  },
  {
    id: "sample",
    date: "2024-03-10T10:01:00.000Z",
    email: (
      <a
        href={`/activity/sample`}
        className="hover:underline flex items-center cursor-pointer"
      >
        <FiExternalLink className="mr-2" />
        kelly@dundermifflin.com
      </a>
    ),
    messages: 2,
    netSentiment: (
      <div className="whitespace-nowrap text-ellipsis overflow-hidden">
        <SentimentIcon number={-1} className="flex-1" />
      </div>
    ),
    feedback: (
      <div className="flex text-left">
        <FiThumbsUp className="text-success-green mr-1" />
        <FiThumbsDown className="text-failure-red mr-1" />
      </div>
    ),
  },
];

const itemsPerPage = 30;

const ActivityList = () => {
  const { user } = useContext(userContext);
  const isTelepathicClient = user?.seller?.client?.name === "Telepathic";
  const useSampleData = isTelepathicClient;
  const [currentPage, setCurrentPage] = React.useState(0);

  const offset = currentPage * itemsPerPage;
  const limit = itemsPerPage;

  const { data, loading, error } = useListConversationsQuery({
    variables: {
      limit,
      offset,
    },
    fetchPolicy: "cache-and-network",
  });

  const conversationsData = React.useMemo(() => {
    if (useSampleData) {
      return sampleConversations;
    }
    if (data?.listConversations) {
      return (data?.listConversations || [])
        .filter((c) => !!c?.messages?.length)
        .map((c) => ({
          id: c?.id,
          date: DateTime.fromISO(c?.createdAt)
            .toRelative()
            ?.replace("ago", "")
            .trim(),
          email: (
            <a
              href={`/activity/${c?.id}`}
              className="hover:underline flex items-center cursor-pointer"
            >
              <FiExternalLink className="mr-2" />
              {c?.buyer?.encryptedEmail}
            </a>
          ),
          messages: c?.messages.length,
          netSentiment: (
            <div className="whitespace-nowrap text-ellipsis overflow-hidden">
              <SentimentIcon
                number={Math.min(
                  c?.messages.reduce(
                    (acc, m) =>
                      acc +
                      (m?.sentiment === null || m?.sentiment === "NEUTRAL"
                        ? 0
                        : m?.sentiment === "POSITIVE"
                        ? 1
                        : -1),
                    0
                  ) || 0,
                  3
                )}
                className="flex-1"
              />
            </div>
          ),
          feedback: (
            <div className="flex text-left">
              {c?.messages
                .reduce((acc: any, message) => {
                  const feedbacks = message?.feedback.map((fb) => fb?.positive);
                  return [...acc, ...feedbacks];
                }, [])
                .filter((i: any, idx: number) => idx < 5)
                .map((f: boolean) =>
                  !!f ? (
                    <FiThumbsUp className="text-success-green mr-1" />
                  ) : (
                    <FiThumbsDown className="text-failure-red mr-1" />
                  )
                )}
            </div>
          ),
        }));
    }
    return [];
  }, [data, useSampleData]);

  // Pagination handlers
  const nextPage = () =>
    setCurrentPage((prev) => Math.min(prev + 1, pageCount - 1));
  const prevPage = () => setCurrentPage((prev) => Math.max(prev - 1, 0));
  const pageCount = Math.ceil((data?.countConversations || 0) / itemsPerPage);
  const labels = {
    email: "Email",
    date: "Time ago",
    messages: "Messages",
    netSentiment: "Net sentiment",
    feedback: "Feedback",
  };

  return (
    <Page childClass="max-w-7xl overflow-hidden" parentClass="!overflow-hidden">
      <Header title="Activity" />
      <div className="flex-1 table-parent mr-16 flex flex-col overflow-hidden">
        {useSampleData && (
          <div className="flex items-start mb-4">
            <Tooltip
              itemChild={
                <Pill
                  mainColor="text-link-blue"
                  text="Sample data"
                  className="bg-link-blue/20"
                  icon={<FiInfo />}
                />
              }
              popoverChild={
                <TooltipChild text="You'll see your actual data when you have some!" />
              }
            />
          </div>
        )}

        <Table
          labels={labels}
          data={conversationsData}
          loading={loading}
          error={error}
          emptyMessage="No activity yet"
        />
        {!loading && !error && conversationsData.length !== 0 && (
          <div className="flex justify-start items-center mt-4">
            <div className="text-gray-500 text-sm">
              Page {currentPage + 1} of {pageCount}
            </div>
            <div className="flex items-center">
              <Tooltip
                itemChild={
                  <button
                    className={`border rounded-md p-2 cursor-pointer text-gray-500 ml-2 ${
                      currentPage === 0
                        ? "!text-gray-300 border-gray-100"
                        : "hover:bg-gray-100"
                    }`}
                    onClick={prevPage}
                    disabled={currentPage === 0}
                  >
                    <FiArrowLeft className="" />
                  </button>
                }
                popoverChild={
                  currentPage !== 0 && <TooltipChild text="Previous page" />
                }
              />

              <Tooltip
                itemChild={
                  <button
                    className={`border rounded-md p-2 cursor-pointer hover:bg-gray-100 text-gray-500 ml-2`}
                    onClick={nextPage}
                    disabled={currentPage >= pageCount - 1}
                  >
                    <FiArrowRight className="" />
                  </button>
                }
                popoverChild={<TooltipChild text="Next page" />}
              />
            </div>
          </div>
        )}
      </div>
    </Page>
  );
};

export default ActivityList;
